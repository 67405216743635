import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import GoogleMapReact from 'google-map-react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Carousel from "react-bootstrap/Carousel";
import Measure from "react-measure";
import {
	GiDoorHandle,
	GiBarbecue,
	GiCityCar,
	GiPerson,
	GiSpikedFence,
	GiHorseHead,
	GiHotSurface,
	GiShower,
	GiBanknote,
	GiBank,
	GiTicket,
} from "react-icons/gi";
import {
	FaWifi,
	FaParking,
	FaHiking,
	FaMountain,
	FaPhoneAlt,
	FaEnvelope,
	FaFacebookSquare,
	FaTableTennis,
} from "react-icons/fa";

const footerBg = "https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989041/medveful/pano_iao0km.jpg";

// TODO: Make content container height the same as the video and set a higher z-index

const Descriptions = {
	bedrooms: `A házban négy különálló szobában összesen 16 fő szállásolható el pótágyakkal együtt.
			Az emeleten mindkét szobában (Papod, Hajag) terasz és fürdőszoba található, míg a magasföldszinten lévő 
			szobákhoz (Gyöngyös) 1 fürdőszoba és egy különálló wc tartozik.`,
	groundFloor: `A magasföldszinten található a konyha, nappali és kijárás a közel 30 négyzetméteres vörösfenyő teraszra és a kertbe.`,
	kitchen: `A konyha teljesen gépesített, mosogatógéppel, vizforralóval, mikróval, sütővel és főzéshez szükséges eszközökkel felszerelt.`,
	livingRoom: `A nappaliban egy nagyképernyős okos tv és egy ebédlőasztal található, a házban WIFI rendelkezésre áll.`,
	garden: `A házhoz egy kb. 1000 négyzetméteres kert tartozik fedett grillsütővel és egy hagyományos tűzrakóhellyel felszerelve. Nyársak, bogrács és grillezéshez szükséges eszközök, valamint tűzifa biztosítva van..`,
	basement: `A pincében egy játékszoba található pingpong asztallal, csocsóval, darts-szal felszerelve. Továbbá egy 4 személyes finn szauna zuhanyzóval.`,
	origins: `Másnéven Cifra-kankalin, melyről a vendégház a nevét kapta egy kizárólag Hárskúton és környékén honos évelő virág. A virágai világossárgák, 1–2 cm szélesek, 4-12 virágból álló ernyőben nyílnak. A szerencsésebbek tavasszal nedves sziklahasadékoban bukkanhatnak rá.`,
};

function calculateDividerOffset(vidHeight) {
	//return -40 - vidHeight * 0.15;
	return -vidHeight * 0.4;
}

const renderMap = () => {
	return (
		// Important! Always set the container height explicitly
		<div style={{
			height: '600px',
			width: '100vw',
			marginTop: '2vw',
		}}>
		  <GoogleMapReact
			bootstrapURLKeys={{ key: "AIzaSyCBXkptG__aioqHETSsuZk0Dc4-VpovXH0" }}
			defaultCenter={{
				lat: 47.187,
				lng: 17.82
			  }}
			defaultZoom={13}
		  >
		  </GoogleMapReact>
		</div>
	);
}

const renderNav = () => {
	return(
		// <Navbar
		// 	fixed="top"
		// 	style={{
		// 		backgroundColor: "rgba(255, 255, 255, 0.7)"
		// 	}}
		// 	bg="light"
		// 	variant="light">
		// 	<Navbar.Brand href="#home">Navbar</Navbar.Brand>
		// 	<Nav className="mr-auto">
		// 		<Nav.Link href="#home">Home</Nav.Link>
		// 		<Nav.Link href="#features">Features</Nav.Link>
		// 		<Nav.Link href="#pricing">Pricing</Nav.Link>
		// 	</Nav>
		// </Navbar>
		<Navbar
			collapseOnSelect
			expand="lg"
			bg="light"
			variant="light"
		>
			<Navbar.Toggle aria-controls="responsive-navbar-nav" />
			<Navbar.Collapse id="responsive-navbar-nav">
				<Nav
					style={{
						justifyContent: "center",
						width: "100%",
					}}
				>
					<Nav.Link
						style={{
							paddingLeft: 40,
							paddingRight: 40,
							color: "rgba(46, 50, 59, 0.7)",
							fontFamily: "Dancing Script",
							fontSize: "1.5em",
							fontWeight: 600,
							borderRight: "1px solid rgba(46, 50, 59, 0.17)",
						}}
						href="#intro"
					>
						Bemutatkozás
					</Nav.Link>
					<Nav.Link
						style={{
							paddingLeft: 40,
							paddingRight: 40,
							color: "rgba(46, 50, 59, 0.7)",
							fontFamily: "Dancing Script",
							fontSize: "1.5em",
							fontWeight: 600,
							borderRight: "1px solid rgba(46, 50, 59, 0.17)",
						}}
						href="#haz"
					>
						A vendégház
					</Nav.Link>
					<Nav.Link
						style={{
							paddingLeft: 40,
							paddingRight: 40,
							color: "rgba(46, 50, 59, 0.7)",
							fontFamily: "Dancing Script",
							fontSize: "1.5em",
							fontWeight: 600,
							borderRight: "1px solid rgba(46, 50, 59, 0.17)",
						}}
						href="#szobak"
					>
						Szobák
					</Nav.Link>
					<Nav.Link
						style={{
							paddingLeft: 40,
							paddingRight: 40,
							color: "rgba(46, 50, 59, 0.7)",
							fontFamily: "Dancing Script",
							fontSize: "1.5em",
							fontWeight: 600,
							borderRight: "1px solid rgba(46, 50, 59, 0.17)",
						}}
						href="#programok"
					>
						Kirándulás és programlehetőségek
					</Nav.Link>
					<Nav.Link
						style={{
							paddingLeft: 40,
							paddingRight: 40,
							color: "rgba(46, 50, 59, 0.7)",
							fontFamily: "Dancing Script",
							fontSize: "1.5em",
							fontWeight: 600,
						}}
						href="#kontakt"
					>
						Árak és foglalás
					</Nav.Link>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
}

const didYouKnow = () => {
	return (
		<Container style={{
			minHeight: 200,
			marginTop: "6vw"
		}}>
			<Row
				style={{
					display: "flex",
					justifyContent: "center",
					paddingBottom: 50
				}}
			>
				<div
					style={{
						width: "50vw",
						height: "2px",
						backgroundColor: "rgba(46, 50, 59, 0.2)",
						borderRadius: "50%",
					}}
				/>
			</Row>
			<Row>
				<Col xs={10}
					style={{
						//backgroundColor: "violet",
						flexDirection: "column",
						display: "flex",
						justifyContent: "center",
						flex: 1,
					}}
				>
					<span
						style={{
							textAlign: "left",
							fontSize: "3em",
							marginBottom: 10,
							fontFamily: "Dancing Script"
						}}
					>
						Medvefül
					</span>
					<p className="description"
						style={{
							textAlign: "left"
						}}
					>
						{Descriptions.origins}
					</p>
				</Col>
				<Col xs={2}
					style={{
						justifyContent: "center",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<img
						src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989044/medveful/medveful_ebecic.jpg"
						style={{
							width: "15vw",
							height: "15vw",
							//maxWidth: "100%",
							//maxHeight: "100%",
							borderRadius: "100%",
						}}
					/>
				</Col>
			</Row>
			{/* <Row
				style={{
					display: "flex",
					justifyContent: "center",
					paddingTop: 50
				}}
			>
				<div
					style={{
						width: "50vw",
						height: "2px",
						backgroundColor: "rgba(46, 50, 59, 0.2)",
						borderRadius: "50%",
					}}
				/>
			</Row> */}
		</Container>
	);
}

const carouselSurroundings = () => {
	return (
		<Carousel>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989043/medveful/surroundings_town_j7vttf.jpg"
				alt="Falu"
				/>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989040/medveful/surroundings_fields_efaxsk.jpg"
				alt="Főút"
				/>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989043/medveful/topdown_dytxcc.jpg"
				alt="Erdő madártávlatból"
				/>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989040/medveful/surroundings_drone_jt2xqq.jpg"
				alt="Naplemente"
				/>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989042/medveful/surroundings_view_bdx5mi.jpg"
				alt="Környező hegyek"
				/>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989042/medveful/surroundings_lookout_rgnyob.jpg"
				alt="Vadles"
				/>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989041/medveful/surroundings_fall_z2qssa.jpg"
				alt="Ősz"
				/>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989040/medveful/surroundings_fall_topdown_aepent.jpg"
				alt="Őszi erdő"
				/>
			</Carousel.Item>

			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1610916752/medveful/mindnk%C3%A9pppp_vis4fr.jpg"
				alt="Kilátó"
				/>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1610916752/medveful/DJI_0390_xwjrzg.jpg"
				alt="Kilátó"
				/>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1610916753/medveful/mk%C3%A9pp_j5bvvm.jpg"
				alt="Téli erdő"
				/>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1610916752/medveful/mk%C3%A9pppp_sgnypb.jpg"
				alt="Erdei út"
				/>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1610916752/medveful/haafelf%C3%A9r_ljulii.jpg"
				alt="Fasor télen"
				/>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1610916751/medveful/haelf%C3%A9r_xewqrr.jpg"
				alt="Fasor télen"
				/>
			</Carousel.Item>
		</Carousel>
	);
}

const carouselRooms = () => {
	return (
		<Carousel>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989028/medveful/bedrooms/gy%C3%B6ngy%C3%B6s2_obsuw6.jpg"
				alt="Hálószoba"
				/>
				<Carousel.Caption>
					{/* <h3>Tágas, világos nappali a földszinten</h3> */}
					<p>
						{Descriptions.bedrooms}
					</p>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989028/medveful/bedrooms/gy%C3%B6ngy%C3%B6s1_gsfrfb.jpg"
				alt="Hálószoba"
				/>
				<Carousel.Caption>
					{/* <h3>Tágas, világos nappali a földszinten</h3> */}
					<p>
						{Descriptions.bedrooms}
					</p>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989029/medveful/bedrooms/gy%C3%B6ngy%C3%B6s3_xtxxxw.jpg"
				alt="Hálószoba"
				/>
				<Carousel.Caption>
					{/* <h3>Tágas, világos nappali a földszinten</h3> */}
					<p>
						{Descriptions.bedrooms}
					</p>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1610916926/medveful/bedrooms/Lenti_f%C3%BCrd%C5%91_replace_oa4erd.jpg"
				alt="Fürdőszoba"
				/>
				<Carousel.Caption>
					{/* <h3>Tágas, világos nappali a földszinten</h3> */}
					<p>
						{Descriptions.bedrooms}
					</p>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989030/medveful/bedrooms/hajag2_jefhqc.jpg"
				alt="Hálószoba"
				/>
				<Carousel.Caption>
					{/* <h3>Tágas, világos nappali a földszinten</h3> */}
					<p>
						{Descriptions.bedrooms}
					</p>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989029/medveful/bedrooms/hajag3_kxtycj.jpg"
				alt="Hálószoba"
				/>
				<Carousel.Caption>
					{/* <h3>Tágas, világos nappali a földszinten</h3> */}
					<p>
						{Descriptions.bedrooms}
					</p>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989030/medveful/bedrooms/papod1_sutnba.jpg"
				alt="Hálószoba"
				/>
				<Carousel.Caption>
					{/* <h3>Tágas, világos nappali a földszinten</h3> */}
					<p>
						{Descriptions.bedrooms}
					</p>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989031/medveful/bedrooms/papod3_s9qzbc.jpg"
				alt="Hálószoba"
				/>
				<Carousel.Caption>
					{/* <h3>Tágas, világos nappali a földszinten</h3> */}
					<p>
						{Descriptions.bedrooms}
					</p>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989028/medveful/bedrooms/bathrooms_p8joqj.jpg"
				alt="Fürdőszobák"
				/>
				<Carousel.Caption>
					{/* <h3>Tágas, világos nappali a földszinten</h3> */}
					<p>
						{Descriptions.bedrooms}
					</p>
				</Carousel.Caption>
			</Carousel.Item>
		</Carousel>
	);
}

const carouselSpaces = () => {
	return (
		<Carousel>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989026/medveful/spaces/livingroom1_xnrl7d.jpg"
				alt="Nappali"
				/>
				<Carousel.Caption>
					{/* <h3>Tágas, világos nappali a földszinten</h3> */}
					<p>
						{Descriptions.groundFloor}
					</p>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989027/medveful/spaces/livingroom2_ledmjj.jpg"
				alt="Nappali"
				/>
				<Carousel.Caption>
					{/* <h3>Tágas, világos nappali a földszinten</h3> */}
					<p>
						{Descriptions.livingRoom}
					</p>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989025/medveful/spaces/kitchen_yntnwp.jpg"
				alt="Konyha"
				/>
				<Carousel.Caption>
					{/* <h3>Franciaágyas szoba a földszinten</h3> */}
					<p>
						{Descriptions.kitchen}
					</p>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1610916966/medveful/spaces/Konyha_add_rl2eoa.jpg"
				alt="Konyha"
				/>
				<Carousel.Caption>
					{/* <h3>Franciaágyas szoba a földszinten</h3> */}
					<p>
						{Descriptions.kitchen}
					</p>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989026/medveful/spaces/deck1_bhzkna.jpg"
				alt="Terasz"
				/>
				<Carousel.Caption>
					{/* <h3>Másik franciaágyas szoba a földszinten</h3> */}
					<p>
						{Descriptions.groundFloor}
					</p>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				<img
					className="d-block w-100"
					src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989025/medveful/spaces/deck2_rd23rq.jpg"
					alt="Terasz"
				/>
				<Carousel.Caption>
					{/* <h3>Konyha a földszinten</h3> */}
					<p>
						{Descriptions.garden}
					</p>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989026/medveful/spaces/backyard1_e1vx4t.jpg"
				alt="Kert"
				/>
				<Carousel.Caption>
					{/* <h3>Emeleti szobák</h3> */}
					<p>
						{Descriptions.garden}
					</p>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989025/medveful/spaces/backyard2_ntzybi.jpg"
				alt="Kert"
				/>
				<Carousel.Caption>
					{/* <h3>Szabadidőszoba az alagsorban</h3> */}
					<p>
						{Descriptions.garden}
					</p>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989026/medveful/spaces/fireplace_hk2nov.jpg"
				alt="Kert"
				/>
				<Carousel.Caption>
					{/* <h3>Szabadidőszoba az alagsorban</h3> */}
					<p>
						{Descriptions.garden}
					</p>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1610916752/medveful/Mindenk%C3%A9pp_lubzlf.jpg"
				alt="Kert"
				/>
				<Carousel.Caption>
					{/* <h3>Szabadidőszoba az alagsorban</h3> */}
					<p>
						{Descriptions.garden}
					</p>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1610916753/medveful/mk%C3%A9p_ed4hdc.jpg"
				alt="Kert"
				/>
				<Carousel.Caption>
					{/* <h3>Szabadidőszoba az alagsorban</h3> */}
					<p>
						{Descriptions.garden}
					</p>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989027/medveful/spaces/recroom1_mmm2mb.jpg"
				alt="Szabadidőszoba"
				/>
				<Carousel.Caption>
					{/* <h3>Szabadidőszoba az alagsorban</h3> */}
					<p>
						{Descriptions.basement}
					</p>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				<img
				className="d-block w-100"
				src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989028/medveful/spaces/recroom2_xjflye.jpg"
				alt="Szabadidőszoba"
				/>
				<Carousel.Caption>
					{/* <h3>Szabadidőszoba az alagsorban</h3> */}
					<p>
						{Descriptions.basement}
					</p>
				</Carousel.Caption>
			</Carousel.Item>
		</Carousel>
	);
}

function App() {
	const [vidHeight, setVidHeight] = useState(-1);

	return (
		<div className="App"
			style={{overFlowX: "hidden"}}
		>
			{ renderNav() }
			<div style={{
					height: vidHeight,
				}}>
				<Measure
					bounds
					onResize={ contentRect => {
						console.log(`Height ${contentRect.bounds.height}`)
						setVidHeight(contentRect.bounds.height)
					} }
				>
					{({ measureRef }) => (
					<video
						ref={measureRef}
						style={styles.video}
						playsInline
						autoPlay
						muted
						loop
						poster="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989042/medveful/placeholder_wj9z74.png"
					>
						<source src="https://res.cloudinary.com/dcy1ojcly/video/upload/v1606989064/medveful/vid_mqhfi8.mp4" type="video/mp4" />
					</video>
					)}
				</Measure>
				<h1
						style={{
							//color: "#fff",
							//color: "#2e323b",
							color: "rgba(46, 50, 59, 0.8)",
							fontFamily: "Dancing Script, cursive",
							fontWeight: 200,
							fontSize: "10vw",
							marginTop: "10vw",
							//fontSize: 80,
							verticalAlign: "center",
							//textShadow: "2px 2px 4px rgba(40,40,40,0.3)"
						}}
					>
						Medvefül Vendégház
					</h1>

					<h2
						style={{
							//color: "#fff",
							//color: "#000",
							color: "rgba(255, 255, 255, 0.75)",
							fontFamily: "Dancing Script",
							//fontFamily: "Montserrat",
							fontSize: "5vw",
							marginTop: "3vw",
							//textShadow: "2px 2px 3px rgba(40,40,40,0.3)"
						}}
					>
						Hárskút, Magas-Bakony
					</h2>
			</div>
			<div style={{position: "relative" }}>
				<img
					src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989043/medveful/brush_divider_ytbsmo.png"
					style={{
						width: "100vw",
						top: calculateDividerOffset(vidHeight),
						left: 0,
						position: "absolute",
					}}
				/>
			</div>
			<Container
				id="intro"
				style={{marginTop: -50}}
			>
				<Row>
					<span
						className="sub-header"
						style={{
							textAlign: "left",
							marginBottom: "1em",
							fontSize: "3em",
						}}	
					>
						Bemutatkozás
					</span>
				</Row>
				<Row
					style={{
						//height: 500,
						//paddingTop: "3vw",
						//backgroundColor: "slategray"
					}}
				>
					<Col xs={7}
						style={{
							//backgroundColor: "teal",
							padding: 0,
						}}
					>
						<img
							src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1611174771/medveful/IMG_3351_rxj58b.jpg"
							style={{
								width: "auto",
								//height: "auto",
								maxWidth: "100%",
								maxHeight: "100%",
								borderRadius: 3,
							}}
						/>
					</Col>
					<Col
						style={{
							//backgroundColor: "violet",
							flexDirection: "column",
							display: "flex",
							justifyContent: "center",
							flex: 1,
						}}
					>
						<p
							className="description"
							style={{
								color: "#2e323b",
								//fontSize: "2vw",
								textAlign: "left"
								//fontFamily: "Freight Text Pro"
							}}
							>
							A Medvefül Vendégház a Magas-Bakonyban, 500 méter tengerszint feletti magasságon lévő kis faluban, Hárskúton található, Veszprémtől 15 percre autóval.
						</p>

						<p
							className="description"
							style={{
								color: "#2e323b",
								textAlign: "left"
								//fontFamily: "Freight Text Pro"
							}}
							>
							Csendes környezetben, az erdő közelében családoknak, baráti társaságoknak kíváló lehetőséget biztosít a kikapcsolódásra.
						</p>

						<span className="description" style={{textAlign: "left", marginTop: "2em"}}>
							<FaPhoneAlt /> Rudolf Péter <a href="tel:06309407361">06-30/9407-361</a>
						</span>
						<span className="description" style={{textAlign: "left", marginTop: "0.5em"}}>
							<FaEnvelope /> <a href="mailto:peter.rudolf@holimex.hu">peter.rudolf@holimex.hu</a>
						</span>
						<span className="description" style={{textAlign: "left", marginTop: "0.5em"}}>
							<a href="https://hu-hu.facebook.com/medveful/">
								<FaFacebookSquare/> Facebook oldal
							</a>
						</span>
					</Col>
				</Row>
			</Container>

			{didYouKnow()}

			<Container
				id="haz"
				style={{
					//backgroundColor: "#cfcfcf",
					marginTop: "6vw"
				}}
			>
				<Row
					style={{
						flex: 1,
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<div
						className="subheader-bg"
						// style={{
						// 	paddingTop: 16,
						// 	paddingBottom: 16,
						// 	paddingLeft: "3vw",
						// 	paddingRight: "3vw",
						// 	backgroundColor: "rgba(46, 50, 59,0.08)",
						// }}
					>
						<span
							className="sub-header"
							>
							A vendégház
						</span>
					</div>
				</Row>
				<Row
					style={{
						//height: 500,
						marginTop: "6vw",
						//backgroundColor: "slategray"
					}}
				>
					<Col
						style={{
							//backgroundColor: "violet",
							flexDirection: "column",
							justifyContent: "center",
							display: "flex",
							flex: 1,
						}}
					>
						<ul
							className="description"
							style={{textAlign: "left", paddingLeft: "3vw"}}
						>
							<li>
								<GiDoorHandle className="icon" />
								<span className="pad-left">4 szoba</span>
							</li>

							<li>
								<GiShower className="icon" />
								<span className="pad-left">3 zuhanyzó, 1 fürdőkád, 5 WC</span>
							</li>

							<li>
								<GiPerson className="icon" />
								<span className="pad-left">12 férőhely (+4 pótágy)</span>
							</li>
						
							<li>
								<GiBarbecue className="icon" />
								<span className="pad-left">2 grill/bogrács hely</span>
							</li>

							<li>
								<GiHotSurface className="icon" />
								<span className="pad-left">4 fős finn szauna</span>
							</li>

							<li>
								<GiSpikedFence className="icon" />
								<span className="pad-left">1000 négyzetméter zárt kert</span>
							</li>

							<li>
								<FaParking className="icon" />
								<span className="pad-left">Ingyenes parkolás</span>
							</li>

							<li>
								<FaWifi className="icon" />
								<span className="pad-left">WIFI</span>
							</li>

							<li>
								<FaTableTennis className="icon" />
								<span className="pad-left">Játékszoba(ping-pong, csocsó, darts)</span>
							</li>
						</ul>
					</Col>
					<Col xs={7}
						style={{
							//backgroundColor: "teal",
							padding: 0,
						}}
					>
						<img
							src="https://res.cloudinary.com/dcy1ojcly/image/upload/v1606989044/medveful/overhead_yard_t0spsf.jpg"
							style={{
								width: "auto",
								//height: "auto",
								maxWidth: "100%",
								maxHeight: "100%",
								borderRadius: 3,
							}}
						/>
					</Col>
				</Row>
			</Container>

			<Container
				id="szobak"
				style={{
					//backgroundColor: "#cfcfcf",
					marginTop: "6vw"
				}}
			>
				<Row
					style={{
						flex: 1,
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<div
						className="subheader-bg"
						// style={{
						// 	paddingTop: 16,
						// 	paddingBottom: 16,
						// 	paddingLeft: "3vw",
						// 	paddingRight: "3vw",
						// 	backgroundColor: "rgba(46, 50, 59,0.08)",
						// }}
					>
						<span
							className="sub-header"
							>
							Hálószobák
						</span>
					</div>
				</Row>
				<Row
					style={{
						//height: 500,
						marginTop: "6vw",
						//backgroundColor: "slategray"
					}}
				>
					<Col
						style={{
							//backgroundColor: "teal",
							padding: 0,
							borderRadius: 10
						}}
					>
						{ carouselRooms() }
					</Col>
				</Row>
			</Container>

			<Container
				style={{
					//backgroundColor: "#cfcfcf",
					marginTop: "6vw"
				}}
			>
				<Row
					style={{
						flex: 1,
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<div
						className="subheader-bg"
						// style={{
						// 	paddingTop: 16,
						// 	paddingBottom: 16,
						// 	paddingLeft: "3vw",
						// 	paddingRight: "3vw",
						// 	backgroundColor: "rgba(46, 50, 59,0.08)",
						// }}
					>
						<span
							className="sub-header"
							>
							További szobák és a kert
						</span>
					</div>
				</Row>
				<Row
					style={{
						//height: 500,
						marginTop: "6vw",
						//backgroundColor: "slategray"
					}}
				>
					<Col
						style={{
							//backgroundColor: "teal",
							padding: 0,
							borderRadius: 10
						}}
					>
						{ carouselSpaces() }
					</Col>
				</Row>
			</Container>

			<Container
				id="programok"
				style={{
					//backgroundColor: "#cfcfcf",
					marginTop: "6vw"
				}}
			>
				<Row
					style={{
						flex: 1,
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<div
						className="subheader-bg"
						// style={{
						// 	paddingTop: 16,
						// 	paddingBottom: 16,
						// 	paddingLeft: "3vw",
						// 	paddingRight: "3vw",
						// 	backgroundColor: "rgba(46, 50, 59,0.08)",
						// }}
					>
						<span
							className="sub-header"
							>
							Kirándulás és programlehetőségek
						</span>
					</div>
				</Row>
				<Row
					style={{
						//height: 500,
						marginTop: "6vw",
						//backgroundColor: "slategray"
					}}
				>
					<Col
						style={{
							//backgroundColor: "teal",
							padding: 0,
							borderRadius: 10
						}}
					>
						{ carouselSurroundings() }
					</Col>
				</Row>
				<Row>
					<Col
						style={{
							//backgroundColor: "violet",
							flexDirection: "column",
							justifyContent: "center",
							display: "flex",
							flex: 1,
						}}
					>
						<p className="description" style={{paddingTop: "2vw", marginBottom: 0}}>
							A közelben számos túraútovonal található kilátókhoz és egyéb helyi látnivalókhoz.
							<br></br>
							<FaHiking /> Papod 4 km - 644 m
							<br></br>
							<FaHiking /> Borzas 2 km - 624m 
							<br></br>
							<FaHiking /> Hajag 6 km - 646 m
							<br></br>
							<FaMountain /> Kőrishegy 12km - 710m
							<br></br>
							<br></br>
							<GiHorseHead style={{paddingBottom: 4}}/> A lovaglást kedvelők figyelmébe ajánljuk a Hárskúti Lovas Klubot 
						</p>
					</Col>
				</Row>
			</Container>

			{/* { renderMap() } */}

			<Container
				id="kontakt"
				style={{
					//backgroundColor: "#cfcfcf",
					marginTop: "6vw"
				}}
			>
				<Row
					style={{
						flex: 1,
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<div
						className="subheader-bg"
					>
						<span
							className="sub-header"
							>
							Árak és foglalás
						</span>
					</div>
				</Row>
				<Row>
					<Col
						className="description"
						style={{
							flexDirection: "column",
							justifyContent: "center",
							display: "flex",
							flex: 1,
							paddingTop: "2vw"
						}}
					>
						{/* <span className="contact-row"
							style={{
								marginBottom: "1em",
								fontWeight: 600
							}}
						>
							2022 Szeptember 30-ig
						</span> */}
						<ul>
							<li>
								Felnőtt 
								<b> 9500 Ft / fő / éj</b>
							</li>
							<li>
								Gyerek 1 - 12 éves
								<b> 4500 Ft / fő / éj</b>
							</li>
							<li>
								Kutya
								<b> 2000 Ft / eb / éj</b>
							</li>
							{/* <li>
								Egy éjszaka felára
								<b> + 1000 Ft </b>
							</li> */}
							<li>
								Egy ágyas szoba felára
								<b> 2000 Ft / éj </b>
							</li>
						</ul>
					</Col>
				</Row>
			</Container>

			<Container
				style={{
					//backgroundColor: "#cfcfcf",
					marginTop: "6vw"
				}}
			>
				<Row
					style={{
						flex: 1,
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<div
						className="subheader-bg"
					>
						<span
							className="sub-header"
							>
							Fizetési módok
						</span>
					</div>
				</Row>

				<Row>
					<Col
						className="description"
						style={{
							flexDirection: "column",
							justifyContent: "center",
							display: "flex",
							flex: 1,
							paddingTop: "2vw"
						}}
					>
						<ul>
							<li
								style={{
									paddingBottom: 15
								}}
							>
								<GiBanknote className="icon" style={{paddingBottom: 5}} />
								<span style={{paddingLeft: 5}}>
									Helyszínen készpénz
								</span>
							</li>
							<li
								style={{
									paddingBottom: 15
								}}
							>
								<GiBank className="icon" style={{paddingBottom: 5}} />
								<span style={{paddingLeft: 5}}>
									Átutalás
								</span>
							</li>
							<li
								style={{
									paddingBottom: 15
								}}
							>
								<GiTicket className="icon" style={{paddingBottom: 5}} />
								<span style={{paddingLeft: 5}}>
									Széchenyi Szép-kártya
								</span>
								<ul
									style={{
										fontSize: "0.9em",
										listStyleType: "none"
									}}
								>
									<li>
										Szállás zseb
									</li>
									<li>
										Vendéglátás zseb
									</li>
									<li>
										Szabadidő zseb
									</li>
								</ul>
							</li>
						</ul>
					</Col>
				</Row>
				<Row>
					<Col
						className="description"
						style={{
							flexDirection: "column",
							justifyContent: "center",
							display: "flex",
							flex: 1,
							paddingTop: "2vw"
						}}
					>
						<span className="contact-row">
							<FaPhoneAlt /> Rudolf Péter <a href="tel:06309407361"></a> 06-30/9407-361
						</span>
						<span className="contact-row">
							<FaEnvelope/> <a href="mailto:peter.rudolf@holimex.hu">peter.rudolf@holimex.hu</a>
						</span>
						<span className="contact-row">
							<a href="https://hu-hu.facebook.com/medveful/">
								<FaFacebookSquare/> Facebook oldal
							</a>
						</span>
						<span className="contact-row">
							<a href="https://harskut.kornyeke.hu/latnivalok-programok?P=2">Látnivalók, programok</a>
						</span>
					</Col>
				</Row>
			</Container>

			<div
				className="footer"
				style={{
					width: "100vw",
					height: "14vw",
					//backgroundColor: "teal",
					justifyContent: "center",
					textAlign: "center",
					flex: 1,
					flexDirection: "column",
					color: "violet",
					marginTop: "6vw",
					fontSize: "3vw",
					backgroundImage: `url(${footerBg})`,
					backgroundSize: "auto"
				}}
			>
			</div>
		</div>
	);
}

var styles = {
	video: {
		//objectFit: "cover",
		width: "100vw",
		//height: "100vh",
		position: "absolute",
		top: 56,
		left: 0,
		zIndex: -1,
	},
	navItem: {
		paddingLeft: 40,
		paddingRight: 40,
		color: "rgba(46, 50, 59, 0.7)",
		fontFamily: "Raleway",
		fontSize: "1em",
		fontWeight: 600
	}
};

export default App;
